import { Module } from '@/shared/types/modules';
import { config } from '../config/config';
import fetcher from '../lib/fetcher';

export const fetchTenantModules = async (tenant: string | null): Promise<Module[]> => {
    if (!tenant) throw Error('failed to fetch tenant modules as tenant string missing');
    return fetcher<Module[]>(`${config.apiUrl}/modules?tenantName=${tenant}`, {
        method: 'GET',
    });
};
