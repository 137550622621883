import { Module } from '@/shared/types/modules';
import ModulesList from './ModulesList';
import './ModulesSection.css';

interface ModulesSectionProps {
    userModules: Module[];
    tenantModules: Module[];
    error: Error | null;
}

const ModulesSection = ({ userModules, tenantModules, error }: ModulesSectionProps) => {
    return (
        <div className="modules-container">
            <div className="modules-content">
                <h3 className="modules-header">Platforms available in your SMARTR Solution</h3>
                <hr className="divider modules-content-divider" />
                <ModulesList userModules={userModules} tenantModules={tenantModules} error={error} />
            </div>
        </div>
    );
};

export default ModulesSection;
