import { GetUsersParams, GetUsersResponse } from '@/shared/types/users';
import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';

export const fetchUsers = async (params: GetUsersParams & { hasNoModules?: boolean }): GetUsersResponse => {
    if (!params.tenantName) throw Error('failed to fetch users as tenant string missing');

    const queryParams = new URLSearchParams();
    queryParams.append('tenantName', params.tenantName);
    queryParams.append('page', (params.page || 1).toString());

    if (params.searchTerm) {
        queryParams.append('searchTerm', params.searchTerm);
    }

    if (params.hasNoModules) {
        queryParams.append('moduleIds', '');
    } else if (params.moduleIds && params.moduleIds.length > 0) {
        const moduleIdsString = params.moduleIds.join(',');
        queryParams.append('moduleIds', moduleIdsString);
    }

    if (params.limit) {
        queryParams.append('limit', params.limit.toString());
    }

    const queryString = queryParams.toString();

    return fetcher<GetUsersResponse>(`${config.apiUrl}/users?${queryString}`, { method: 'GET' });
};
