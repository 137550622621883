import { useQuery } from '@tanstack/react-query';
import { GetUsersParams } from '@/shared/types/users';
import { fetchUsers } from '../api/users';

interface UseUsersParams extends GetUsersParams {
    hasNoModules?: boolean;
}

export const FETCH_USERS_QUERY_KEY = 'fetch/users';

const useUsers = ({ tenantName, page = 1, limit, searchTerm, moduleIds, hasNoModules }: UseUsersParams) => {
    return useQuery({
        queryKey: [FETCH_USERS_QUERY_KEY, tenantName, page, limit, searchTerm, moduleIds, hasNoModules],
        queryFn: () => fetchUsers({ tenantName, page, limit, searchTerm, moduleIds, hasNoModules }),
    });
};

export default useUsers;
