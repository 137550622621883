import { Module } from '@/shared/types/modules';
import UserAdminModuleBubbles from './UserAdminModuleBubbles';
import './UserAdminTableModules.css';

interface UserAdminTableModulesProps {
    modules: Module[];
}

const UserAdminTableModules = ({ modules }: UserAdminTableModulesProps) => {
    const orderedModules = modules.sort((a, b) => a.moduleType.localeCompare(b.moduleType));
    const firstTwoModules = orderedModules.slice(0, 2);
    const remainingCount = modules.length - 2;

    return (
        <div className="bubble-container">
            {firstTwoModules.map((module, index) => (
                <UserAdminModuleBubbles key={index} label={module.moduleType} />
            ))}
            {remainingCount > 0 && <UserAdminModuleBubbles label={`+${remainingCount}`} isSmall />}
        </div>
    );
};

export default UserAdminTableModules;
