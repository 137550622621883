import { RxLayers, RxLightningBolt } from 'react-icons/rx';
import { GoInfinity, GoGear } from 'react-icons/go';
import { HiOutlineSparkles } from 'react-icons/hi2';
import { FiCodesandbox } from 'react-icons/fi';
import { LuLeaf } from 'react-icons/lu';
import { IconType } from 'react-icons';
import { Module } from '@/shared/types/modules';

const iconMap: { [key: string]: IconType } = {
    energy: RxLightningBolt,
    automation: GoInfinity,
    spaces: RxLayers,
    infrastructure: LuLeaf,
    maintenance: GoGear,
    intelligence: HiOutlineSparkles,
};

/** Gets the icon type for the given moduleType  */
const getModuleIcon = (moduleType: Module['moduleType']) => {
    if (moduleType in iconMap) return iconMap[moduleType];
    return FiCodesandbox;
};

export default getModuleIcon;
