import { UpdateUserBody, UpdateUserResponse } from '@/shared/types/users';
import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';

export type UpdateUserOptions = {
    userId: string;
    body: UpdateUserBody;
    tenant: string | null;
};

export default async function updateUserModules({ userId, body, tenant }: UpdateUserOptions): UpdateUserResponse {
    if (!tenant) throw Error('update user modules as tenant string is missing');

    const queryString = `?tenantName=${tenant}`;

    return fetcher<UpdateUserResponse>(`${config.apiUrl}/users/${userId}/modules${queryString}`, {
        method: 'PUT',
        body,
    });
}
