import { Module } from '@/shared/types/modules';
import ModuleButton from './ModuleButton';
import InfoIndicator from '../../../components/ui/InfoIndicator';
import sortByEnabledAlpha from '../utils/sortByEnabledAlpha';

interface ModulesListProps {
    userModules: Module[];
    tenantModules: Module[];
    error: Error | null;
}

const ModulesList = ({ userModules, tenantModules, error }: ModulesListProps) => {
    if (error) return <InfoIndicator title="Error" message={error.message} />;

    if (tenantModules.length === 0) {
        return (
            <InfoIndicator
                title="No applications"
                message="Your account does not have any applications available at the moment. Please contact the SMARTR team for assistance. For Equans users please create a new request on the IT self service portal."
            />
        );
    }

    const userModuleTypes = userModules.map((module) => module.moduleType);
    const modulesWithDisabledState = tenantModules.map((module) => ({ ...module, disabled: !userModuleTypes.includes(module.moduleType) }));
    const sortedModules = modulesWithDisabledState.sort(sortByEnabledAlpha);

    return (
        <div className="modules-buttons">
            {sortedModules.map(({ id, moduleType, url, disabled }) => (
                <ModuleButton key={id} moduleType={moduleType} url={url} disabled={disabled} />
            ))}
        </div>
    );
};

export default ModulesList;
