import { User } from '@/shared/types/users';
import Modal from './Modal';
import UserForm from './UserForm';

interface UserFormModalProps {
    showUserForm: boolean;
    onClose: () => void;
    selectedEditUser: null | User;
}

const UserFormModal = ({ showUserForm, onClose, selectedEditUser }: UserFormModalProps) => {
    return (
        <Modal isOpen={showUserForm} handleClose={onClose}>
            <UserForm onClose={onClose} selectedEditUser={selectedEditUser} />
        </Modal>
    );
};

export default UserFormModal;
