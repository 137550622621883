import { CreateUserBody, CreateUserResponse } from '@/shared/types/users';
import { config } from '../../../config/config';
import fetcher from '../../../lib/fetcher';

export default async function createUser(body: CreateUserBody): CreateUserResponse {
    return fetcher<CreateUserResponse>(`${config.apiUrl}/users`, {
        method: 'POST',
        body,
    });
}
