import { useContext } from 'react';
import { User } from '@/shared/types/users';
import UserAdminTableModules from './UserAdminTableModules';
import UserTableRowOptions from './UserTableRowOptions';
import EditUserFormContext from '../contexts/EditUserFormContext';

type UserAdminTableRowProps = {
    user: User;
    selectedUserId: string | null;
    onClickUserOptions: (userId: string) => void;
};

const UserAdminTableRow = ({ user, selectedUserId, onClickUserOptions }: UserAdminTableRowProps) => {
    const setSelectedEditUser = useContext(EditUserFormContext);
    const { id } = user;

    const handleEditUserClick = () => {
        if (setSelectedEditUser) setSelectedEditUser(user);
        onClickUserOptions(id);
    };
    return (
        <tr key={id}>
            <td>{id}</td>
            <td>{user.email}</td>
            <td>
                <UserAdminTableModules modules={user.modules} />
            </td>
            <td>
                <UserTableRowOptions
                    isOptionsOpen={selectedUserId === id}
                    onClickUserOptions={() => onClickUserOptions(id)}
                    onEditUserClick={handleEditUserClick}
                />
            </td>
        </tr>
    );
};

export default UserAdminTableRow;
